import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import { API_BASE_URLS } from '../../utils/constant';
import { ProductDataType, ProductPriceDataType, ProductStockDataType } from '../../@customTypes/product';
import axiosInstance, { axiosDeleteInstance } from '../../utils/axios';
import { SyncProductDataType } from '../../@customTypes/syncproduct';

type ProductState = {
  isLoading: boolean;
  isLoadingSelected: boolean;
  isSyncing: boolean;
  isCustomerSyncing: boolean;
  isProductSyncing: boolean;
  error: boolean;
  success: boolean;
  totalProducts: number;
  productList: ProductDataType[];
  productPriceList: ProductPriceDataType[];
  deleteProductMultiple: ProductDataType[];
  productStockList: ProductStockDataType[];
  selectedProduct: ProductDataType | null;
  selectedProductPrice: ProductPriceDataType | null;
  selectedProductStock: ProductStockDataType | null;
  totalStockBalanceQty: number;
  syncproductList: SyncProductDataType[];
  myobCreds: any;
  myobLoginStatus: boolean;
};

const initialState: ProductState = {
  isLoading: false,
  isLoadingSelected: false,
  isSyncing: false,
  isCustomerSyncing: false,
  isProductSyncing: false,
  error: false,
  success: false,
  totalProducts: 0,
  productList: [],
  productPriceList: [],
  deleteProductMultiple: [],
  productStockList: [],
  selectedProduct: null,
  selectedProductPrice: null,
  selectedProductStock: null,
  totalStockBalanceQty: 0,
  syncproductList: [],
  myobCreds: [],
  myobLoginStatus: false,
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    startCustomerSync(state) {
      state.isCustomerSyncing = true;
    },
    stopCustomerSync(state) {
      state.isCustomerSyncing = false;
    },
    startProductSync(state) {
      state.isProductSyncing = true;
    },
    stopProductSync(state) {
      state.isProductSyncing = false;
    },
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // START SYNCING
    startSyncing(state) {
      state.isSyncing = true;
    },
    //STOP SYNCING
    stopSyncing(state) {
      state.isSyncing = false;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
    // START LOADING
    startLoadingSelected(state) {
      state.isLoadingSelected = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // IS SUCCESS
    isSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload;
    },
    // PRODUCT LISTING
    getProductListSuccess(state, action) {
      state.isLoading = false;
      state.totalProducts = action.payload?.totalCount;
      state.productList = action.payload?.products;
    },
    getProductPriceListSuccess(state, action) {
      state.isLoading = false;
      state.productPriceList = action.payload;
    },
    getdeleteProductMulti(state, action) {
      state.isLoading = false;
      state.deleteProductMultiple = action.payload;
    },
    getProductStockListSuccess(state, action) {
      state.isLoading = false;
      state.productStockList = action.payload;
    },
    getSyncProduct(state, action) {
      state.isLoading = false;
      state.isSyncing = false;
      state.syncproductList = action.payload;
    },
    getSyncProductFromUserManagement(state, action) {
      state.isLoading = false;
      state.isSyncing = false;
      state.syncproductList = action.payload;
    },
    getAllSync(state, action) {
      state.isLoading = false;
      state.syncproductList = action.payload;
    },
    getMyObAuth(state, action) {
      state.isLoading = false;
      state.syncproductList = action.payload;
    },

    getMyObCreds(state, action) {
      state.isLoading = false;
      state.myobCreds = action.payload;
    },

    getMyObToken(state, action) {
      state.isLoading = false;
      state.syncproductList = action.payload;
    },

    getXeroSyncData(state, action) {
      state.isLoading = false;
      state.syncproductList = action.payload;
    },

    setMyobLoginStatus(state, action) {
      state.isLoading = false;
      state.myobLoginStatus = action.payload; // Store the login status properly
    },

    setSelectedProduct(state, action) {
      state.isLoading = true;
      if (action.payload) {
        const tempProduct = action.payload;
        const normalizedProductPriceList = state.productPriceList.map((price) => JSON.parse(JSON.stringify(price)));
        const tempProductStock = state.productStockList?.filter((stock: ProductStockDataType) => stock.product_id === tempProduct.id)[0];
        const tempProductPrice = normalizedProductPriceList?.filter((price: ProductPriceDataType) => price.product_id === tempProduct.productId)[0];

        state.selectedProduct = tempProduct;
        state.selectedProductPrice = tempProductPrice;
        state.selectedProductStock = tempProductStock;
      } else {
        state.selectedProduct = null;
        state.selectedProductPrice = null;
        state.selectedProductStock = null;
      }
    },
  },
});

// Reducer
export default slice.reducer;

export function getProductById({ companyId, id }: { companyId: number; id: any }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.get(`${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.product}/${id}`);
      if (response?.error) {
        throw new Error(response?.error);
      } else dispatch(slice.actions.setSelectedProduct(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function getProductList({ companyId, payload }: { companyId: number; payload: any }) {
  return async () => {
    dispatch(slice.actions.startLoadingSelected());
    try {
      const response: any = await axiosInstance.post(`${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.product}/list`, payload);
      if (response?.error) {
        throw new Error(response?.error);
      } else dispatch(slice.actions.getProductListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function deleteProduct({ companyId, id }: { companyId: number; id: number }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.delete(`${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.product}/${id}/delete`);
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function editProduct({ companyId, payload }: { companyId: number; payload: any }) {
  const { id, ...restPayload } = payload;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(`${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.product}/${id}/edit`, restPayload);
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function addProduct({ companyId, payload }: { companyId: number; payload: any }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(`${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.product}/add`, payload);
      if (response?.error) {
        throw new Error(response?.error);
      }
      return response.data;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function addBulkProduct({ companyId, payload }: { companyId: number; payload: any }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(`${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.product}/add/bulk`, payload);
      if (response?.error) {
        throw new Error(response?.error);
      }
      return response.data;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function getProductPriceList({ companyId, productId, payload }: { companyId: number; productId: number; payload: any }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      if (productId === 0) {
        return dispatch(slice.actions.stopLoading());
      }
      const response: any = await axiosInstance.post(`${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.product}/${productId}${API_BASE_URLS.price}/list`, payload);
      if (response?.error) {
        throw new Error(response?.error);
      } else {
        dispatch(slice.actions.getProductPriceListSuccess(response.data));
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.stopLoading());
      throw new Error(error.message);
    } finally {
      dispatch(slice.actions.stopLoading());
    }
  };
}

export function deleteProductPrice({ companyId, productId, id }: { companyId: number; productId: number; id: number }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.delete(`${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.product}/${productId}${API_BASE_URLS.price}/${id}/delete`);
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}
export function deleteProductMulti({ companyId, payload }: { companyId: number; payload: any }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        // `http://192.168.1.128:3000/api/company/385/product/multidelete`,
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.product}/multidelete`,
        payload
      );
      // return response;
      if (response?.error) {
        throw new Error(response?.error);
      } else dispatch(slice.actions.getdeleteProductMulti(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}
export function deleteOrderMulti({ companyId, payload }: { companyId: number; payload: any }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(`${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.order}/multideleteOrder`, payload);
      // return response;
      if (response?.error) {
        throw new Error(response?.error);
      } else dispatch(slice.actions.getdeleteProductMulti(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function editProductPrice({ companyId, productId, payload }: { companyId: number; productId: number; payload: any }) {
  const { id, ...restPayload } = payload;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(`${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.product}/${productId}${API_BASE_URLS.price}/${id}/edit`, restPayload);
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function addProductPrice({ companyId, productId, payload }: { companyId: number; productId: number; payload: any }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(`${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.product}/${productId}${API_BASE_URLS.price}/add`, payload);
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function getProductStockList({ companyId, productId, payload }: { companyId: number; productId: number; payload: any }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      if (productId === 0) {
        return dispatch(slice.actions.stopLoading());
      }
      const response: any = await axiosInstance.post(`${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.product}/${productId}${API_BASE_URLS.stock}/list`, payload);
      if (response?.error) {
        throw new Error(response?.error);
      } else dispatch(slice.actions.getProductStockListSuccess(response.data));
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function deleteProductStock({ companyId, productId, id }: { companyId: number; productId: number; id: number }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.delete(`${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.product}/${productId}${API_BASE_URLS.stock}/${id}/delete`);
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function editProductStock({ companyId, productId, payload }: { companyId: number; productId: number; payload: any }) {
  const { id, ...restPayload } = payload;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(`${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.product}/${productId}${API_BASE_URLS.stock}/${id}/edit`, restPayload);
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function addProductStock({ companyId, productId, payload }: { companyId: number; productId: number; payload: any }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(`${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.product}/${productId}${API_BASE_URLS.stock}/add`, payload);
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function addVendorToProduct({ companyId, productId, payload }: { companyId: number; productId: number; payload: any }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(`${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.product}/${productId}${API_BASE_URLS.vendor}/add`, payload);
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}
export function addExpiryToProduct({ companyId, payload }: { companyId: number; payload: any }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(`${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.product}/getProductExpiry`, payload);
      if (response?.error) {
        throw new Error(response?.error);
      }
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function removeVendorFromProduct({
  companyId,
  productId,
  payload,
}: {
  companyId: number;
  productId: number;
  payload: {
    vendor_id: number;
  };
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosDeleteInstance(payload).delete(`${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.product}/${productId}${API_BASE_URLS.vendor}/delete`);
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function getAllSync(companyId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startSyncing());
    try {
      const response: any = await axiosInstance.get(`${API_BASE_URLS.company}/${companyId}/myob/sync`);
      if (response?.error) {
        throw new Error(response?.error);
      } else {
        // dispatch(slice.actions.removeSelectedCustomer());
        dispatch(slice.actions.getAllSync(response.data));
        dispatch(slice.actions.stopSyncing());
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.stopSyncing());
      throw new Error(error.message);
    }
  };
}

export function getSyncProduct(companyId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startSyncing());
    try {
      const response: any = await axiosInstance.get(`${API_BASE_URLS.company}/${companyId}/myob/sync-product`);
      if (response?.error) {
        throw new Error(response?.error);
      } else {
        // dispatch(slice.actions.removeSelectedCustomer());
        dispatch(slice.actions.getSyncProduct(response.data));
        dispatch(slice.actions.stopSyncing());
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.stopSyncing());
      throw new Error(error.message);
    }
  };
}

export function getSyncProductFromUserManagement(companyId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startProductSync());
    try {
      const response: any = await axiosInstance.get(`${API_BASE_URLS.company}/${companyId}/myob/sync-create-product`);
      if (response?.error) {
        throw new Error(response?.error);
      } else {
        // dispatch(slice.actions.removeSelectedCustomer());
        dispatch(slice.actions.getSyncProductFromUserManagement(response.data));
        dispatch(slice.actions.stopProductSync());
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.stopProductSync());
      throw new Error(error.message);
    }
  };
}

export function getMyObAuth(companyId: number, payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(`${API_BASE_URLS.company}/${companyId}/myob/authorize`, payload);
      if (response?.error) {
        throw new Error(response?.error);
      } else {
        // dispatch(slice.actions.removeSelectedCustomer());
        dispatch(slice.actions.getMyObAuth(response.data));
        return response;
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function getMyObCreds(companyId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.get(`${API_BASE_URLS.company}/${companyId}/myob/credentials`);
      if (response?.error) {
        throw new Error(response?.error);
      } else {
        // dispatch(slice.actions.removeSelectedCustomer());
        dispatch(slice.actions.getMyObCreds(response.data));
        return response;
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function getMyObToken(companyId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(`${API_BASE_URLS.company}/${companyId}/myob/token`);

      if (response?.error) {
        throw new Error(response?.error);
      } else {
        dispatch(slice.actions.getMyObToken(response.data));
        return response;
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function getXeroSyncData(companyId: number) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.get(`http://localhost:9000/xero/syncXeroData?companyId=${companyId}`);

      if (response?.error) {
        throw new Error(response?.error);
      } else {
        // dispatch(slice.actions.removeSelectedCustomer());
        dispatch(slice.actions.getXeroSyncData(response.data));
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function startLoading() {
  return async () => dispatch(startLoading);
}

export function stopLoading() {
  return async () => dispatch(stopLoading);
}
export function startLoadingSelected() {
  return async () => dispatch(startLoadingSelected);
}

export function removeError() {
  return async () => dispatch(slice.actions.hasError(false));
}

export function setSuccess(payload: boolean) {
  return async () => dispatch(slice.actions.isSuccess(payload));
}
export function setSeletedProduct(payload: ProductDataType | null) {
  return async () => dispatch(slice.actions.setSelectedProduct(payload));
}

export function getMyobLoginStatus(companyId: number) {
  return async (dispatch: any) => {
    try {
      const response = await axiosInstance.get(`${API_BASE_URLS.company}/${companyId}/myob/login-status`);

      // Make sure we're accessing the correct property
      const isLoggedIn = response?.data?.isLoggedIn;

      // Dispatch the login status
      dispatch(slice.actions.setMyobLoginStatus(isLoggedIn));

      return isLoggedIn;
    } catch (error: any) {
      console.error('Error in getMyobLoginStatus:', error);
      // Return false in case of error
      dispatch(slice.actions.setMyobLoginStatus(false));
      return false;
    }
  };
}

export function getCategoryBasedProducts(companyId: number, categoryId: any, limit: number = 10, page: number, sortBy?: string, status?: any, stock?: any) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.get(`${API_BASE_URLS.company}/${companyId}/product/categoryBasedProducts/list`, {
        params: {
          category_id: categoryId,
          limit: limit,
          page: page,
          ...(sortBy && { sortBy }),
          ...(status && { status }),
          ...(stock && { stock }),
        },
      });
      if (response?.error) {
        throw new Error(response?.error);
      } else {
        return response;
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    } finally {
      dispatch(slice.actions.stopLoading());
    }
  };
}
