import { ICONS } from '../../utils/icons';
import { PATH_DASHBOARD } from '../../routes/paths';
import { MODULE_NAMES, RoleTypes } from '../../utils/constant';

const sidebarConfig = () => {
  const userString = localStorage.getItem('user');
  const user = userString ? JSON.parse(userString) : null;
  const myobstatus = user?.myobstatus;
  const integration_id = user?.integration_id;

  return [
    // GENERAL
    {
      subheader: 'general',
      items: [
        {
          title: 'Dashboard',
          path: PATH_DASHBOARD.root,
          icon: ICONS.dashboard,
          access: RoleTypes.SUPER_ADMIN,
          module_name: MODULE_NAMES.app,
        },
        {
          title: 'Dashboard',
          path: PATH_DASHBOARD.root,
          icon: ICONS.dashboard,
          access: RoleTypes.SALESMAN,
          module_name: MODULE_NAMES.address,
        },
      ],
    },
    // MANAGEMENT
    {
      subheader: 'management',
      items: [
        {
          title: 'Company',
          path: PATH_DASHBOARD.general.company,
          icon: ICONS.company,
          access: RoleTypes.SUPER_ADMIN,
          module_name: MODULE_NAMES.app,
        },
        {
          title: 'Company Subscription',
          path: PATH_DASHBOARD.general.subscription,
          icon: ICONS.subscription,
          access: RoleTypes.SUPER_ADMIN,
          module_name: MODULE_NAMES.app,
        },
        {
          title: 'Product Management',
          icon: ICONS.address,
          children: [
            {
              title: 'Browse Products',
              path: PATH_DASHBOARD.general.browseProducts,
              icon: ICONS.product,
              module_name: MODULE_NAMES.product,
            },
            {
              title: 'Manage Product',
              path: PATH_DASHBOARD.general.product,
              icon: ICONS.productGrid,
              module_name: MODULE_NAMES.product,
            },
            myobstatus !== 1 &&
              integration_id !== 1 && {
                title: 'Brand',
                path: PATH_DASHBOARD.general.brand,
                icon: ICONS.brand,
                module_name: MODULE_NAMES.brand,
              },
            {
              title: 'Category',
              path: PATH_DASHBOARD.general.category,
              icon: ICONS.category,
              module_name: MODULE_NAMES.category,
            },
            myobstatus !== 1 &&
              integration_id !== 1 && {
                title: 'Vendor',
                path: PATH_DASHBOARD.general.vendor,
                icon: ICONS.vendor,
                module_name: MODULE_NAMES.vendor,
              },
            // Conditionally render Stock Inward
            myobstatus !== 1 &&
              integration_id !== 1 && {
                title: 'Stock Inward',
                path: PATH_DASHBOARD.general.grn_Entry,
                icon: ICONS.grn_Entry,
                module_name: MODULE_NAMES.grn,
              },
            {
              title: 'Order',
              path: PATH_DASHBOARD.general.order,
              icon: ICONS.order,
              module_name: MODULE_NAMES.order,
            },
          ], // Filter out null values
        },
        {
          title: 'Company management',
          icon: ICONS.companymanagement,
          children: [
            {
              title: 'Customer',
              path: PATH_DASHBOARD.general.customer,
              icon: ICONS.customer,
              module_name: MODULE_NAMES.customer,
            },
            {
              title: 'Warehouse',
              path: PATH_DASHBOARD.general.warehouse,
              icon: ICONS.warehouse,
              module_name: MODULE_NAMES.warehouse,
            },
            {
              title: 'Zone',
              path: PATH_DASHBOARD.general.zone,
              icon: ICONS.zone,
              module_name: MODULE_NAMES.zone,
            },
            {
              title: 'Tax',
              path: PATH_DASHBOARD.general.tax,
              icon: ICONS.tax,
              module_name: MODULE_NAMES.tax,
            },
          ],
        },
        {
          title: 'User management',
          icon: ICONS.usermanagement,
          children: [
            {
              title: 'User',
              path: PATH_DASHBOARD.user.list,
              icon: ICONS.user,
              module_name: MODULE_NAMES.user,
            },
            {
              title: 'Role',
              path: PATH_DASHBOARD.general.role,
              icon: ICONS.role,
              module_name: MODULE_NAMES.role,
            },
          ],
        },
        (myobstatus === 1 || integration_id === 1) && {
          title: 'Third Party Integration',
          path: PATH_DASHBOARD.general.sync,
          icon: ICONS.sync,
          module_name: MODULE_NAMES.role,
        },
        {
          title: 'CMS',
          path: PATH_DASHBOARD.general.cms,
          icon: ICONS.cms,
          access: RoleTypes.SUPER_ADMIN,
          module_name: MODULE_NAMES.cms_page,
        },
        {
          title: 'App Config',
          path: PATH_DASHBOARD.general.generalSetting,
          icon: ICONS.settings,
          access: RoleTypes.SUPER_ADMIN,
          module_name: MODULE_NAMES.general_setting,
        },
      ],
    },
  ];
};

export default sidebarConfig;
