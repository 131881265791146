import { enqueueSnackbar, closeSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { MIconButton } from './@material-extend';

const SnackbarNotification = ({ message, variant }: { message: any; variant: 'success' | 'error' | 'warning' | 'info' }) => {
  return enqueueSnackbar(message, {
    variant,
    action: (key) => (
      <MIconButton size='small' onClick={() => closeSnackbar(key)}>
        <Icon color='#fff' icon={closeFill} />
      </MIconButton>
    ),
  });
};

export default SnackbarNotification;
