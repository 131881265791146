import CryptoJS from 'crypto-js';

export const API_BASE_URLS = {
  // AUTH API END POINT
  login: `/auth/login`,
  refresh: `/auth/token/refresh`,
  resetpassword: `/auth/password/reset`,
  verifyOTP: `/auth/otp/verify`,
  updatePassword: `/auth/password/update`,
  changePassword: `/auth/password-change`,
  userChangePassword: `/auth/user-password-change`,
  resendOTP: `/auth/otp/resend`,
  // COMPANY
  getUserList: `/user/list`,
  addZoneToUser: `/zau/add`,
  deleteUserFromZone: `/zau/delete`,
  user: `/user`,
  // COMPANY
  getCompanyList: `/company/list`,
  company: `/company`,
  //SUBSCRIPTION
  subscription: `/subscription`,
  // ROLE
  getRoleList: `/role/list`,
  role: `/role`,
  // BRAND
  getBrandList: `/brand/list`,
  brand: `/brand`,
  // PAYMENT
  getPaymentList: `/pyment_method/list`,
  payment: `/pyment_method`,
  // TAX
  getTaxList: `/tax/list`,
  tax: `/tax`,
  // CuSTOMER
  getCustomerList: `/customer/list`,
  customer: `/customer`,
  // Warehouse
  getWarehouseList: `/warehouse/list`,
  warehouse: `/warehouse`,
  // ADDRESS
  getAddressList: `/address/list`,
  getCountryList: `/countries`,
  getStateList: `/states`,
  getCityList: `/cities`,
  address: `/address`,
  country: `/country`,
  state: `/state`,
  city: `/city`,
  // PAYMENT METHODS
  getPaymentMethod: `/paymentMethod/list`,
  paymentMethod: `/paymentMethod`,
  // CATEGORY
  getCategoryList: `/category/list`,
  category: `/category`,
  // VENDORS
  getVendorList: `/vendor/list`,
  vendor: `/vendor`,
  // ZONES
  getZoneList: `/zone/list`,
  zone: `/zone`,
  // PRODUCTS
  getProductList: `/product/list`,
  getProductPrice: `/price/list`,
  getProductStock: `/stock/list`,
  addVendorToProduct: `/vendor/add`,
  delteVendorToProduct: `/vendor/delete`,
  product: `/product`,
  price: `/price`,
  stock: `/stock`,
  // VISITS
  getVisitList: `/visit/list`,
  visit: `/visit`,
  // STORES
  getStoreList: `/store/list`,
  store: `/store`,
  // ORDERS
  getOrderList: `/order/list`,
  addToCart: `/order/add-to-cart`,
  orderDetailsEdit: `/details/edit`,
  orderDetailsDelete: `/details/delete`,
  orderDetailsList: `/details/list`,
  order: `/order`,
  // GRN
  getgrnEntryList: `/grn_entry/list`,
  grn_entry: `/grn_entry`,
  grn_entryaddstock: `/grn_entryaddstock`,
  grn_details: `/grn_details`,
  // GENERAL SETTING
  grn_settings: `/grn_settings`,
  // CMS
  cms_page: `/cms_page`,
  // File
  file_add: `/file/add`,
  file_list: `/file/list`,
  //Admin
  admin: `/admin`,
  //SuperAdmin
  superadmin: `/superadmin`,
  xero: `/xero`,
};

export const RoleTypes = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  SALESMAN: 'SALESMAN',
  DELIVERY: 'DELIVERY',
  WAREHOUSE: 'WAREHOUSE',
};

export const SUPER_ADMIN_MODULES = {
  app: 'APPLICATION_MANAGEMENT',
  cms_page: 'CMS_MANAGEMENT',
  general_setting: 'GENERAL_SETTING_MANAGEMENT',
};

export const MODULE_NAMES = {
  address: 'ADDRESS_MANAGEMENT',
  app: 'APPLICATION_MANAGEMENT',
  category: 'CATEGORY_MANAGEMENT',
  cms_page: 'CMS_MANAGEMENT',
  company: 'COMPANY_MANAGEMENT',
  customer: 'CUSTOMER_MANAGEMENT',
  general_setting: 'GENERAL_SETTING_MANAGEMENT',
  grn: 'GRN_MANAGEMENT',
  grnstock: 'GRNSTOCK_MANAGEMENT',
  order: 'ORDER_MANAGEMENT',
  product: 'PRODUCT_MANAGEMENT',
  productsgrid: 'PRODUCTSGRID_MANAGEMENT',
  role: 'ROLE_MANAGEMENT',
  setting: 'SETTINGS_MANAGEMENT',
  tax: 'TAX_MANAGEMENT',
  user: 'USER_MANAGEMENT',
  vendor: 'VENDOR_MANAGEMENT',
  warehouse: 'WAREHOUSE_MANAGEMENT',
  zone: 'ZONE_MANAGEMENT',
  brand: 'BRAND MANAGEMENT',
  sync: 'SYNC_MANAGEMENT',
};

export const emailValidationPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
export const passwordValidation = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/;
//@ts-ignore
export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const NumRegExp = /^\d+$/;
export const PositiveIntegerRegExp = /^[1-9]\d*$/;

export const PositiveDecimalRegExp = /^(?:[1-9]\d*|\d*\.\d+|\d+\.\d*)$/;

// export const NumRegExpDot = /^\d*\.?\d*$/;
export const NumRegExpDot = /^([0-9]*\.?[0-9]{0,2})$/;

export const lowerCaseRegex = /[a-z]/g;
export const decimalRegex = /^\d+\.\d{0,2}$/;

export const OrderStatus: {
  [key: string]: {
    label: string;
    color: string;
    backgroundColor: string;
    height: string;
    minWidth: string;
    lineHeight: string;
    borderRadius: string;
    // border: string;
    cursor: string;
    alignItems: string;
    whiteSpace: string;
    display: string;
    justifyContent: string;
    textTransform: string;
    padding: string;
    fontSize: string;
    fontWeight: string;
    transition: string;
    textAlign: string;
    // width: string;
  };
} = {
  0: {
    label: 'Draft',
    color: 'rgb(99, 115, 129)',
    backgroundColor: 'rgba(145, 158, 171, 0.16)',
    height: '24px',
    minWidth: '24px',
    lineHeight: '0',
    borderRadius: '6px',
    cursor: 'default',
    // -webkit-box-align: center,
    alignItems: 'center',
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    // -webkit-box-pack: center,
    justifyContent: 'center',
    textTransform: 'capitalize',
    padding: '0px 6px',
    fontSize: ' 0.75rem',
    fontWeight: '700',
    transition: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    textAlign: 'center',
  },
  1: {
    label: 'Placed ( Order )',
    height: '24px',
    minWidth: '24px',
    lineHeight: '0',
    borderRadius: '6px',
    cursor: 'default',
    // -webkit-box-align: center,
    alignItems: 'center',
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    // -webkit-box-pack: center,
    justifyContent: 'center',
    textTransform: 'capitalize',
    padding: '0px 6px',
    fontSize: ' 0.75rem',
    fontWeight: '700',
    transition: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: ' rgb(171 58 135)',
    backgroundColor: 'rgb(201 0 255 / 18%)',
    textAlign: 'center',
  },
  2: {
    label: 'Ready To Pick',
    height: '24px',
    minWidth: '24px',
    lineHeight: '0',
    borderRadius: '6px',
    cursor: 'default',
    // -webkit-box-align: center,
    alignItems: 'center',
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    // -webkit-box-pack: center,
    justifyContent: 'center',
    textTransform: 'capitalize',
    padding: '0px 6px',
    fontSize: ' 0.75rem',
    fontWeight: '700',
    transition: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: 'rgb(17, 141, 87)',
    backgroundColor: 'rgba(34, 197, 94, 0.16)',
    textAlign: 'center',
  },
  3: {
    label: 'Out for delivery',
    height: '24px',
    minWidth: '24px',
    lineHeight: '0',
    borderRadius: '6px',
    cursor: 'default',
    // -webkit-box-align: center,
    alignItems: 'center',
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    // -webkit-box-pack: center,
    justifyContent: 'center',
    textTransform: 'capitalize',
    padding: '0px 6px',
    fontSize: ' 0.75rem',
    fontWeight: '700',
    transition: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: 'rgb(183, 110, 0)',
    backgroundColor: 'rgba(255, 171, 0, 0.16)',
    textAlign: 'center',
  },
  4: {
    label: 'Delivered',
    height: '24px',
    minWidth: '24px',
    lineHeight: '0',
    borderRadius: '6px',
    cursor: 'default',
    // -webkit-box-align: center,
    alignItems: 'center',
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    // -webkit-box-pack: center,
    justifyContent: 'center',
    textTransform: 'capitalize',
    padding: '0px 6px',
    fontSize: ' 0.75rem',
    fontWeight: '700',
    transition: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: 'rgb(57 140 213)',
    backgroundColor: 'rgb(112 141 217 / 23%)',
    textAlign: 'center',
  },
  // 5: {
  //   label: "Back order",
  //   height: "24px",
  //   minWidth: "24px",
  //   lineHeight: "0",
  //   borderRadius: "6px",
  //   cursor: "default",
  //   alignItems: "center",
  //   whiteSpace: "nowrap",
  //   display: "inline-flex",
  //   justifyContent: "center",
  //   textTransform: "capitalize",
  //   padding: "0px 6px",
  //   fontSize: " 0.75rem",
  //   fontWeight: "700",
  //   transition: "all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  //   color: "rgb(57 140 213)",
  //   backgroundColor: "rgb(112 141 217 / 23%)",
  //   textAlign: "center"
  // }
};
export const PaymentStatus: {
  [key: string]: {
    label: string;
    color: string;
    backgroundColor: string;
    height: string;
    minWidth: string;
    lineHeight: string;
    borderRadius: string;
    // border: string;
    cursor: string;
    alignItems: string;
    whiteSpace: string;
    display: string;
    justifyContent: string;
    textTransform: string;
    padding: string;
    fontSize: string;
    fontWeight: string;
    transition: string;
    textAlign: string;
    // width: string;
  };
} = {
  0: {
    label: 'Pending',
    color: 'rgb(99, 115, 129)',
    backgroundColor: 'rgba(145, 158, 171, 0.16)',
    height: '24px',
    minWidth: '24px',
    lineHeight: '0',
    borderRadius: '6px',
    cursor: 'default',
    // -webkit-box-align: center,
    alignItems: 'center',
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    // -webkit-box-pack: center,
    justifyContent: 'center',
    textTransform: 'capitalize',
    padding: '0px 6px',
    fontSize: ' 0.75rem',
    fontWeight: '700',
    transition: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    textAlign: 'center',
  },
  1: {
    label: 'Paid',
    height: '24px',
    minWidth: '24px',
    lineHeight: '0',
    borderRadius: '6px',
    cursor: 'default',
    // -webkit-box-align: center,
    alignItems: 'center',
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    // -webkit-box-pack: center,
    justifyContent: 'center',
    textTransform: 'capitalize',
    padding: '0px 6px',
    fontSize: ' 0.75rem',
    fontWeight: '700',
    transition: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: 'rgb(17, 141, 87)',
    backgroundColor: 'rgb(34 197 94 / 16%)',
    textAlign: 'center',
  },
};

export const statusList: {
  [key: string]: {
    label: string;
    color: string;
    backgroundColor: string;
    height: string;
    minWidth: string;
    lineHeight: string;
    borderRadius: string;
    // border: string;
    cursor: string;
    alignItems: string;
    whiteSpace: string;
    display: string;
    justifyContent: string;
    textTransform: string;
    padding: string;
    fontSize: string;
    fontWeight: string;
    transition: string;
    textAlign: string;
  };
} = {
  0: {
    label: 'Inactive',
    height: '24px',
    minWidth: '24px',
    lineHeight: '0',
    borderRadius: '6px',
    cursor: 'default',
    // -webkit-box-align: center,
    alignItems: 'center',
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    // -webkit-box-pack: center,
    justifyContent: 'center',
    textTransform: 'capitalize',
    padding: '0px 6px',
    fontSize: ' 0.75rem',
    fontWeight: '700',
    transition: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: 'rgb(99, 115, 129)',
    backgroundColor: 'rgba(145, 158, 171, 0.16)',
    textAlign: 'center',
  },
  1: {
    label: 'Active',
    height: '24px',
    minWidth: '24px',
    lineHeight: '0',
    borderRadius: '6px',
    cursor: 'default',
    // -webkit-box-align: center,
    alignItems: 'center',
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    // -webkit-box-pack: center,
    justifyContent: 'center',
    textTransform: 'capitalize',
    padding: '0px 6px',
    fontSize: ' 0.75rem',
    fontWeight: '700',
    transition: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: 'rgb(17, 141, 87)',
    backgroundColor: 'rgba(34, 197, 94, 0.16)',
    textAlign: 'center',
  },
};
export const myobStatus: {
  [key: string]: {
    label: string;
    color: string;
    backgroundColor: string;
    height: string;
    minWidth: string;
    lineHeight: string;
    borderRadius: string;
    // border: string;
    cursor: string;
    alignItems: string;
    whiteSpace: string;
    display: string;
    justifyContent: string;
    textTransform: string;
    padding: string;
    fontSize: string;
    fontWeight: string;
    transition: string;
    textAlign: string;
  };
} = {
  0: {
    label: 'False',
    height: '24px',
    minWidth: '24px',
    lineHeight: '0',
    borderRadius: '6px',
    cursor: 'default',
    // -webkit-box-align: center,
    alignItems: 'center',
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    // -webkit-box-pack: center,
    justifyContent: 'center',
    textTransform: 'capitalize',
    padding: '0px 6px',
    fontSize: ' 0.75rem',
    fontWeight: '700',
    transition: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: 'rgb(99, 115, 129)',
    backgroundColor: 'rgba(145, 158, 171, 0.16)',
    textAlign: 'center',
  },
  1: {
    label: 'True',
    height: '24px',
    minWidth: '24px',
    lineHeight: '0',
    borderRadius: '6px',
    cursor: 'default',
    // -webkit-box-align: center,
    alignItems: 'center',
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    // -webkit-box-pack: center,
    justifyContent: 'center',
    textTransform: 'capitalize',
    padding: '0px 6px',
    fontSize: ' 0.75rem',
    fontWeight: '700',
    transition: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: 'rgb(17, 141, 87)',
    backgroundColor: 'rgba(34, 197, 94, 0.16)',
    textAlign: 'center',
  },
};

export const integration_id: {
  [key: string]: {
    label: string;
    color: string;
    backgroundColor: string;
    height: string;
    minWidth: string;
    lineHeight: string;
    borderRadius: string;
    // border: string;
    cursor: string;
    alignItems: string;
    whiteSpace: string;
    display: string;
    justifyContent: string;
    textTransform: string;
    padding: string;
    fontSize: string;
    fontWeight: string;
    transition: string;
    textAlign: string;
  };
} = {
  0: {
    label: 'No',
    height: '24px',
    minWidth: '24px',
    lineHeight: '0',
    borderRadius: '6px',
    cursor: 'default',
    // -webkit-box-align: center,
    alignItems: 'center',
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    // -webkit-box-pack: center,
    justifyContent: 'center',
    textTransform: 'capitalize',
    padding: '0px 6px',
    fontSize: ' 0.75rem',
    fontWeight: '700',
    transition: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: 'rgb(99, 115, 129)',
    backgroundColor: 'rgba(145, 158, 171, 0.16)',
    textAlign: 'center',
  },
  1: {
    label: 'Yes',
    height: '24px',
    minWidth: '24px',
    lineHeight: '0',
    borderRadius: '6px',
    cursor: 'default',
    // -webkit-box-align: center,
    alignItems: 'center',
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    // -webkit-box-pack: center,
    justifyContent: 'center',
    textTransform: 'capitalize',
    padding: '0px 6px',
    fontSize: ' 0.75rem',
    fontWeight: '700',
    transition: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: 'rgb(17, 141, 87)',
    backgroundColor: 'rgba(34, 197, 94, 0.16)',
    textAlign: 'center',
  },
};

export const integration_name: {
  [key: string]: {
    label: string;
    color: string;
    backgroundColor: string;
    height: string;
    minWidth: string;
    lineHeight: string;
    borderRadius: string;
    // border: string;
    cursor: string;
    alignItems: string;
    whiteSpace: string;
    display: string;
    justifyContent: string;
    textTransform: string;
    padding: string;
    fontSize: string;
    fontWeight: string;
    transition: string;
    textAlign: string;
  };
} = {
  0: {
    label: 'Non-MyOb',
    height: '24px',
    minWidth: '24px',
    lineHeight: '0',
    borderRadius: '6px',
    cursor: 'default',
    // -webkit-box-align: center,
    alignItems: 'center',
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    // -webkit-box-pack: center,
    justifyContent: 'center',
    textTransform: 'capitalize',
    padding: '0px 6px',
    fontSize: ' 0.75rem',
    fontWeight: '700',
    transition: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: 'rgb(99, 115, 129)',
    backgroundColor: 'rgba(145, 158, 171, 0.16)',
    textAlign: 'center',
  },
  1: {
    label: 'MyOb',
    height: '24px',
    minWidth: '24px',
    lineHeight: '0',
    borderRadius: '6px',
    cursor: 'default',
    // -webkit-box-align: center,
    alignItems: 'center',
    whiteSpace: 'nowrap',
    display: 'inline-flex',
    // -webkit-box-pack: center,
    justifyContent: 'center',
    textTransform: 'capitalize',
    padding: '0px 6px',
    fontSize: ' 0.75rem',
    fontWeight: '700',
    transition: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: 'rgb(17, 141, 87)',
    backgroundColor: 'rgba(34, 197, 94, 0.16)',
    textAlign: 'center',
  },
};

export const unitOfMeasure = ['kg', 'box', 'ltr', 'tons', 'piece'];

export const AustralianBankNames = [
  'Commonwealth Bank - CBA',
  'National Australia Bank - NAB',
  'Westpac - WBC',
  'Australia & New Zealand Banking Corp - ANZ',
  'Macquarie Bank - MQG',
  'Suncorp Group - SUN',
  'Bendigo & Adelaide Bank - BEN',
  'Virgin Money - VUK',
  'Bank of Queensland - BOQ',
  'Bank South Pacific - BFL',
  // "Other"
];

export const IndustryArr = [
  'Retail',
  'Wholesellers',
  'Manufacturing',
  'Technology',
  'Healthcare',
  'Education',
  'Hospitality',
  'Transportation',
  'Agriculture',
  'Entertainment',
  'Sports',
  'Other',
];

export const capitalizeFirstAndLastAfterHyphen = (inputString: any) => {
  const words = inputString.split(' ');

  for (let i = 0; i < words.length; i++) {
    if (i < 9 || (i > 0 && words[i - 1].endsWith('-'))) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
  }

  const lastIndex = words.lastIndexOf('-');

  if (lastIndex !== -1 && lastIndex < words.length - 1) {
    const lastWord = words[lastIndex + 1];
    words[lastIndex + 1] = lastWord === lastWord.toUpperCase() ? lastWord : lastWord.toUpperCase();
  }
  return words.join(' ');
};

export const convertToBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);

    reader.readAsDataURL(file);
  });
};

export const formatError = (errObj: { message: string }) => {
  if (errObj) {
    let errorString = errObj.message;
    switch (errorString) {
      case "Data too long for column 'upc' at row 1":
        return "Data too long for column 'Barcode'";
      case 'item_location must be a string':
        return 'Item location must be required';
      case 'Failed':
        return 'You are unauthorized';
      case "Data too long for column 'first_name' at row 1":
        return 'Data too long for First name';
      case "Data too long for column 'name' at row 1":
        return 'Data too long for Name';
      case "Data too long for column 'last_name' at row 1":
        return 'Data too long for Last Name';
      case "Out of range value for column 'tax_amount' at row 1":
        return 'Maximum Quantity reached, try with a lower quantity';
      case 'product_id must be a number conforming to the specified constraints':
        return 'Please select a product before entering the quantity';
      case "Data too long for column 'item_location' at row 1":
        return 'Data too long for Item location';
      case 'poc_name must be shorter than or equal to 30 characters':
        return 'POC Name must be shorter than or equal to 30 characters';
      case "Data too long for column 'username' at row 1":
        return 'Data too long for User name';
      case "Data too long for column 'title' at row 1":
        return 'Data too long for Title';
      case "Cannot read properties of null (reading 'product_id')":
        return 'Please Add Product First';
      case 'qty must be a number conforming to the specified constraints':
        return 'Enter Valid Quantity';
      case 'entry grn number already exists':
        return 'GRN Number already exists';
      case 'ERR_REQUIRED_PASSWORD':
        return 'Invalid Password';
      default:
        if (errorString.includes('You have an error in your SQL syntax')) {
          return "Can't go back in the backward status";
        } else if (errorString.includes('Duplicate') || errorString.includes('duplicate')) {
          let errorArr = errorString.split("'");
          let keyName = errorArr[3].replace(/[\._]/g, ' ');
          const words = keyName.split(' ');
          if (errorString.includes('grn_entry.unique_grn_per_company')) {
            return 'GRN Number already exists.';
          }
          if (errorString.includes('grn_entry.unique_purchase_number_company')) {
            return 'Purchase Number already exists';
          }
          if (errorString.includes('vendors.company_id')) {
            return 'poc contact already exists';
          }
          if (words.length > 2) {
            let result = words.slice(1).join(' ');
            if (result === 'org name') {
              result = 'Customer Name';
            }
            return `${result} already exists`;
          }
          if (words.length === 2) {
            let modifiedKey = words[0].endsWith('s') ? words[0].slice(0, -1) : words[0];
            modifiedKey = `${modifiedKey} ${words[1]}`;
            if (modifiedKey.toLowerCase() === 'companie name') {
              modifiedKey = 'Company Name';
            }
            return `${modifiedKey} already exists`;
          }
          return `${keyName} already exists`;
        }
        return errorString;
    }
  }
};

const aesKey = CryptoJS.enc.Utf8.parse('aeskeyaeskeyaeskeyaeskeyaeskey32');
const aesIv = CryptoJS.enc.Utf8.parse('0123456789abcdef');
const aesOptions = {
  iv: aesIv,
  mode: CryptoJS.mode.CBC,
  padding: CryptoJS.pad.Pkcs7,
};

export const getEncryptedString = (text: string) => {
  const ciphertext = CryptoJS.AES.encrypt(text, aesKey, aesOptions).ciphertext.toString();
  return ciphertext;
};
export const getDecryptedString = (encrypted_string: string) => {
  const encoded = { ciphertext: CryptoJS.enc.Hex.parse(encrypted_string) };
  const decodedText = CryptoJS.enc.Utf8.stringify(
    //@ts-ignore
    CryptoJS.AES.decrypt(encoded, aesKey, aesOptions)
  );
  return decodedText;
};

export const isDate1GreaterThanDate2 = (d1: any, d2: any) => {
  const date1 = new Date(d1);
  const date2 = new Date(d2);

  // Extract year, month, and day from the dates
  const year1 = date1.getFullYear();
  const month1 = date1.getMonth();
  const day1 = date1.getDate();

  const year2 = date2.getFullYear();
  const month2 = date2.getMonth();
  const day2 = date2.getDate();

  // Compare the dates without considering time
  if (year1 > year2 || (year1 === year2 && month1 > month2) || (year1 === year2 && month1 === month2 && day1 > day2)) {
    return 1;
  } else if (year1 < year2 || (year1 === year2 && month1 < month2) || (year1 === year2 && month1 === month2 && day1 < day2)) {
    return -1;
  } else {
    return 0;
  }
};

export const arePermissionsMissing = (requiredPermission: any) => {
  const userPermissionsJSON = localStorage.getItem('user');
  let UserDetails = userPermissionsJSON ? JSON.parse(userPermissionsJSON) : null;

  let userPermission = UserDetails?.role?.write_permissions;

  const hasRequiredPermission = userPermission.some((permission: any) => permission.module_name === requiredPermission);

  return !hasRequiredPermission;
};

export const formatDate = (inputDateString: any) => {
  // Check if inputDateString is a valid date string
  if (!inputDateString || isNaN(Date.parse(inputDateString))) {
    // Return a default or current date if inputDateString is not valid
    return new Date().toISOString().split('T')[0];
  }

  const originalDate = new Date(inputDateString);

  // Extract year, month, and day
  const year = originalDate.getFullYear();
  const month = String(originalDate.getMonth() + 1).padStart(2, '0');
  const day = String(originalDate.getDate()).padStart(2, '0');

  // Create the formatted date string (YYYY-MM-DD)
  const formattedDate = `${day}-${month}-${year}`;

  return formattedDate;
};
export const formatDateNEW = (inputDate: any) => {
  const [day, month, year] = inputDate.split('-');

  const formattedDate = new Date(`${year}-${month}-${day}`);

  const formattedYear = formattedDate.getFullYear();
  const formattedMonth = String(formattedDate.getMonth() + 1).padStart(2, '0');
  const formattedDay = String(formattedDate.getDate()).padStart(2, '0');

  const result = `${formattedYear}-${formattedMonth}-${formattedDay}`;

  return result;
};

export const formatDateYYYY = (inputDateString: any) => {
  // Check if inputDateString is a valid date string
  if (!inputDateString || isNaN(Date.parse(inputDateString))) {
    // Return a default or current date if inputDateString is not valid
    return new Date().toISOString().split('T')[0];
  }
  const originalDate = new Date(inputDateString);

  // Extract year, month, and day
  const year = originalDate.getFullYear();
  const month = String(originalDate.getMonth() + 1).padStart(2, '0');
  const day = String(originalDate.getDate()).padStart(2, '0');

  // Create the formatted date string (YYYY-MM-DD)
  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

export const capitalizeFirstLetter = (str: string) => {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
};

export const MonthlyDate = (dateString: any) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const date = new Date(dateString);
  const monthIndex = date.getMonth();
  const day = date.getDate();

  return `${months[monthIndex]} ${day}`;
};

export const CurrencyConverter = (amount: any) => {
  // Convert amount to string if it's not already
  const amountStr = String(amount);

  // Check if the amount is in thousands
  if (amountStr.length > 3) {
    const formattedAmount = amountStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return formattedAmount;
  }

  return amountStr;
};

export const formatCurrencyinUSD = (amount: any) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount);
};
