import { alpha, experimentalStyled as styled, Box, Stack, AppBar, Toolbar, Typography } from '@mui/material';
import AccountPopover from './AccountPopover';
import useAuth from '../../hooks/useAuth';
import { capitalizeFirstLetter } from '../../utils/constant';

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 64;

interface RootStyleProps {
  isOpen: boolean;
}

const RootStyle = styled(AppBar)<RootStyleProps>(({ theme, isOpen }: { theme: any; isOpen: boolean }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)',
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${isOpen ? DRAWER_WIDTH - 10 : 64}px)`,
    transition: `width 300ms ease-in-out`,
  },
  [theme.breakpoints.up('md')]: {
    width: `calc(100% - ${isOpen ? DRAWER_WIDTH - 10 : 64}px)`,
    transition: `width 300ms ease-in-out`,
  },
  [theme.breakpoints.up('sm')]: {
    width: `calc(100% - ${isOpen ? DRAWER_WIDTH + 0 : 63}px)`,
    transition: `width 300ms ease-in-out`,
  },
  [theme.breakpoints.up('xs')]: {
    width: `calc(100% - ${isOpen ? DRAWER_WIDTH + 0 : 65}px)`,
    transition: `width 300ms ease-in-out`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }: { theme: any }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

type DashboardNavbarProps = { onOpenSidebar: VoidFunction; isOpen: boolean };

export default function DashboardNavbar({ isOpen, onOpenSidebar }: DashboardNavbarProps) {
  const auth: any = useAuth();

  return (
    <RootStyle isOpen={isOpen}>
      <ToolbarStyle>
        <Typography variant='h4' sx={{ color: 'text.primary' }}>
          Hi, {`${capitalizeFirstLetter(auth?.user?.company.name || '')}`}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction='row' spacing={{ xs: 0.5, sm: 1.5 }}>
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
