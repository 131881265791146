import { tableCellClasses } from '@mui/material';
import { TableRow } from '@mui/material';
import { TableCell, Typography } from '@mui/material';
import { styled } from '@mui/system';

export const getGeneralStyle = () => ({
  no_link: {
    textDecoration: 'none',
    color: 'inherit',
  },

  link: {
    color: '#0045FF',
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
      fontSize: '90%',
      transition: 'all .2s',
    },
  },

  container: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '0 20px',
  },
  marginAuto: {
    margin: 'auto',
  },

  disable: {
    opacity: 0.3,
    'pointer-events': 'none',
  },
  dFlex: {
    display: 'flex',
  },

  alignItemsCenter: {
    alignItems: 'center',
  },
  alignItemsStart: {
    alignItems: 'start',
  },

  justifyContentCenter: {
    justifyContent: 'center',
  },
  justifyContentstart: {
    justifyContent: 'start',
  },
  justifyContentSpace: {
    justifyContent: 'space-between',
  },
  justifyContentEvenly: {
    justifyContent: 'space-evenly',
  },

  flexColumn: {
    flexDirection: 'column',
  },

  flexRow: {
    flexDirection: 'row',
  },
  flexRowReverse: {
    flexDirection: 'row-reverse',
  },

  flex1: {
    flex: 1,
  },
  flex2: {
    flex: 2,
  },
  flex3: {
    flex: 3,
  },
  flex4: {
    flex: 4,
  },
  flex5: {
    flex: 5,
  },
  flex6: {
    flex: 6,
  },
  flex7: {
    flex: 7,
  },

  mt1: {
    marginTop: '5px',
  },

  mt2: {
    marginTop: '10px',
  },

  mt3: {
    marginTop: '15px',
  },

  mt4: {
    marginTop: '20px',
  },

  mt5: {
    marginTop: '25px',
  },

  mb1: {
    marginBottom: ' 5px',
  },

  mb2: {
    marginBottom: '10px',
  },

  mb3: {
    marginBottom: '15px',
  },

  mb4: {
    marginBottom: '20px',
  },

  mb5: {
    marginBottom: '25px',
  },

  ml1: {
    marginLeft: '5px',
  },

  ml2: {
    marginLeft: '10px',
  },

  ml3: {
    marginLeft: '15px',
  },

  ml4: {
    marginLeft: '20px',
  },

  ml5: {
    marginLeft: '25px',
  },

  mr1: {
    marginRight: '5px',
  },

  mr2: {
    marginRight: '10px',
  },

  mr3: {
    marginRight: '15px',
  },

  mr4: {
    marginRight: '20px',
  },

  mr5: {
    marginRight: '25px',
  },

  mblock1: {
    marginBlock: '5px',
  },
  mblock2: {
    marginBlock: '10px',
  },
  mblock3: {
    marginBlock: '15px',
  },
  mblock4: {
    marginBlock: '20px',
  },
  mblock5: {
    marginBlock: '25px',
  },

  mInline1: {
    marginInline: '5px',
  },
  mInline2: {
    marginInline: '10px',
  },
  mInline3: {
    marginInline: '15px',
  },
  mInline4: {
    marginInline: '20px',
  },
  mInline5: {
    marginInline: '25px',
  },

  /* Spacing */

  pt1: {
    paddingTop: '5',
  },

  pt2: {
    paddingTop: '10',
  },

  pt3: {
    paddingTop: '15',
  },

  pt4: {
    paddingTop: '20',
  },

  pt5: {
    paddingTop: '25',
  },

  pb1: {
    paddingBottom: '5px',
  },

  pb2: {
    paddingBottom: '10px',
  },

  pb3: {
    paddingBottom: '15px',
  },

  pb4: {
    paddingBottom: '20px',
  },

  pb5: {
    paddingBottom: '25px',
  },

  pl1: {
    paddingLeft: '5px',
  },

  pl2: {
    paddingLeft: '10px',
  },

  pl3: {
    paddingLeft: '15px',
  },

  pl4: {
    paddingLeft: '20px',
  },

  pl5: {
    paddingLeft: '25px',
  },

  pr1: {
    paddingRight: '5px',
  },

  pr2: {
    paddingRight: '10px',
  },

  pr3: {
    paddingRight: '15px',
  },

  pr4: {
    paddingRight: '20px',
  },

  pr5: {
    paddingRight: '25px',
  },
  pr6: {
    paddingRight: '32px',
  },
  pblock0: {
    padding: 0,
  },

  pblock1: {
    paddingBlock: '5px',
  },
  pblock2: {
    paddingBlock: '10px',
  },
  pblock3: {
    paddingBlock: '15px',
  },
  pblock4: {
    paddingBlock: '20px',
  },
  pblock5: {
    paddingBlock: '25px',
  },

  pInline1: {
    paddingInline: '5px',
  },
  pInline2: {
    paddingInline: '10px',
  },
  pInline3: {
    paddingInline: '15px',
  },
  pInline4: {
    paddingInline: '20px',
  },
  pInline5: {
    paddingInline: '25px',
  },

  /* Utility */
  textCenter: {
    textAlign: 'center',
  },

  textLeft: {
    textAlign: 'left',
  },

  textRight: {
    textAlign: 'right',
  },

  textCapitalize: {
    textTransform: 'capitalize',
  },

  fw600: {
    fontWeight: 600,
  },

  textUppercase: {
    textTransform: 'uppercase',
  },

  w500: {
    width: '500px',
  },
  w100: {
    width: '100%',
  },
  w90: {
    width: '90%',
  },
  w80: {
    width: '80%',
  },
  w70: {
    width: '70%',
  },

  minDialogWidth: {
    width: '600px',
  },

  textDecor: {
    textDecoration: 'none',
  },
  height100: {
    height: '500px',
  },
  borderRadious: {
    borderRadius: '40px',
  },
  borderRadious16: {
    borderRadius: '16px',
  },
  h100: {
    height: '100%',
  },
  AddButtonWidth: {
    width: '155px',
  },
  colorGrey: {
    color: 'grey',
  },
  colorError: {
    color: '#FF4842',
  },
  popoverPaper: {
    '& .MuiPaper-root': {
      boxShadow: '0 0px 0px rgb(0 0 0 / 3%), 0 1px 1px rgb(1 1 1 / 0%), 0 1px 2px rgba(0,0,0,0.1), 0 0px 1px rgb(0 0 0 / 0%), 0 1px 2px #cfcece',
    },
  },

  LineColor: {
    color: '#919eab26',
  },

  textBlue: {
    color: '#0045ff !important',
  },
  textRed: {
    color: '#f70202 !important',
  },
  textGreen: {
    color: '#31bd16 !important',
  },

  borderBlue: {
    border: '1px solid #0045ff !important',
  },
  borderRed: {
    border: '1px solid #f70202 !important',
  },
  borderGreen: {
    border: '1px solid #31bd16 !important',
  },

  btn: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '12px 16px',
    fontFamily: 'Roboto, Arial, sans-serif',
    fontSize: '14px',
    fontWeight: 600,
    textTransform: 'uppercase',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    backgroundColor: '#fff',
    color: '#0045ff',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: '#f3f3f3',
      transition: 'boxShadow backgroundColor 0.3s ease',
    },
  },

  btnBlue: {
    backgroundColor: '#0045ffc2',
    color: '#ffffff',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    transition: 'boxShadow backgroundColor 0.3s ease',
    '&:hover': {
      backgroundColor: '#0045ff',
    },
  },

  btnRed: {
    backgroundColor: '#ff0000c2',
    color: '#ffffff',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    transition: 'boxShadow backgroundColor 0.3s ease',
    '&:hover': {
      backgroundColor: '#f70202',
    },
  },

  btnGreen: {
    backgroundColor: '#31bd16c2',
    color: '#ffffff',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    transition: 'boxShadow backgroundColor 0.3s ease',
    '&:hover': {
      backgroundColor: '#31bd16',
    },
  },

  btnMinWidth: {
    minwidth: '100px',
  },

  SearchBar: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row-reverse',
    maxHeight: '45px',
  },
  ImageCloseButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: '#fff',
    borderRadius: '50%',
    padding: '5px',
    margin: '12px',
    border: '1px solid grey',
  },
  labelStyle: {
    '& label': {
      top: '-10px',
    },
  },
  minWid: {
    minWidth: 120,
    marginTop: 2,
    width: '100%',
  },

  orderMainDiv: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    verticalAlign: 'inherit',
    textAlign: 'left',
    color: 'rgb(99, 115, 129)',
    fontWeight: 600,
    lineHeight: 1.57143,
    fontSize: '0.875rem',
  },
  orderOtrMain: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
  },
  orderFieldClr: {
    lineHeight: '1.57143',
    fontSize: '0.875rem',
    fontWeight: 400,
    display: 'table-cell',
    verticalAlign: 'inherit',
    textAlign: 'left',
    padding: '11px',
    color: 'rgb(99, 115, 129)',
  },
  wraperOrder: {
    alignItems: 'center',
    flexDirection: 'row',
    width: '20%',
    justifyContent: 'space-between',
  },
  TooltipStyle: {
    padding: '20px',
  },
});

export const cartIconStyles = {
  base: {
    fontSize: 24,
    cursor: 'pointer',
  },
  disabled: {
    fontSize: 24,
    color: 'lightgray',
    cursor: 'not-allowed',
  },
  decrease: {
    fontSize: 24,
    color: 'red',
    cursor: 'pointer',
  },
  increase: {
    fontSize: 24,
    color: 'green',
    cursor: 'pointer',
  },
  countText: {
    fontWeight: 'bold',
    width: 20,
    textAlign: 'center',
  },
};

export const SpanLabel = styled('span')(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'end',
  flexDirection: 'column',
  position: 'relative',
  top: 0,
  left: 0,
  height: 0,
  paddingTop: '10px',
  whiteSpace: 'nowrap',
}));

export const CustomTypography = styled(Typography)`
  color: gray;
  font-size: 3rem;
  font-weight: 700;

  /* Media query for screens with a maximum width of 600px */
  @media (max-width: 600px) {
    font-size: 2.5rem;
  }

  /* Media query for screens with a maximum width of 400px */
  @media (max-width: 400px) {
    font-size: 1.5rem;
  }
`;

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#524f82',
    color: theme.palette.common.white,
    padding: '5px 25px',
    boxShadow: 'none',
    '@media (max-width: 600px)': { fontSize: 12, padding: 15 },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '5px 25px',
    '@media (max-width: 600px)': { fontSize: 12, padding: '5px 15px' },
  },
}));

export const StyledTableFooterCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#fff',
    color: theme.palette.common.white,
    padding: 25,
    boxShadow: 'none',
    '@media (max-width: 600px)': { fontSize: 14, padding: 17 },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
    padding: 27,
    fontWeight: 700,
    '@media (max-width: 600px)': { fontSize: 14, padding: 17 },
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  position: 'relative',
  '&:nth-of-type(odd)': { backgroundColor: theme.palette.action.hover },
  '&:last-child td, &:last-child th': { border: 0 },
  '@media (max-width: 600px)': {
    '&:nth-of-type(odd)': { backgroundColor: 'transparent' },
  },
}));

export const StyledTableFooterRow = styled(TableRow)(({ theme }) => ({
  borderTop: '2px solid #ebebeb',
  borderBottom: '2px solid #ebebeb',
  backgroundColor: '#fff',
}));

export const StyledProductNote = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
  fontSize: '14px',
  color: theme.palette.text.secondary,
}));
